<template>
  <div class="space-y-4">
    <base-card
      title="Tambah Koli"
      description="Tambahkan barang yang akan dikemas"
    >
      <div class="space-y-4">
        <div
          v-for="(packet, packetIndex) in packets"
          :key="packetIndex"
          class="space-y-4 rounded-lg border border-gray-200 bg-white p-6"
        >
          <div class="item-stretch flex gap-4">
            <base-input
              fullwidth
              inset
              label="Nama Koli"
              type="text"
              v-model="packets[packetIndex].name"
            />
            <base-button
              :disabled="packets.length < 2"
              custom-height="h-auto"
              :self-end="false"
              :color="packets.length > 1 ? 'danger-secondary' : 'gray'"
              @click="onRemovePacket(packetIndex)"
            >
              <Icon icon="heroicons:trash-solid" class="h-4 w-4" />
              Hapus Koli
            </base-button>
          </div>

          <datatable
            :columns="[
              {
                code: 'kode barang',
                name: 'Kode Barang',
              },
              {
                code: 'nama barang',
                name: 'Nama Barang',
              },
              {
                code: 'jumlah',
                name: 'Jumlah',
              },
              {
                code: 'jumlah batch',
                name: 'Jumlah Batch',
              },
              {
                code: 'aksi',
                name: 'Aksi',
                theadClass: 'text-center',
              },
            ]"
          >
            <template #tbody="{ classes }">
              <template v-if="!packet.products.length">
                <tr :class="classes.tr">
                  <td colspan="5" :class="classes.td">
                    <button
                      class="flex w-full flex-col items-center space-y-2 py-4 text-center text-gray-400 hover:text-gray-500"
                      @click="onAddPacketProduct(packetIndex)"
                    >
                      <Icon icon="heroicons:plus-circle" class="h-6 w-6" />
                      <p class="text-xs font-normal">
                        Tidak ada data<br />Klik untuk menambahkan
                      </p>
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr
                  v-for="(product, index) in packet.products"
                  :key="index"
                  :class="classes.tr"
                >
                  <td :class="classes.td">
                    <p class="font-bold text-gray-900">
                      {{
                        getProductDetail(product.orderDetailOrBonusId)
                          .attributes.product_code
                      }}
                      <span
                        v-if="
                          getProductDetail(product.orderDetailOrBonusId)
                            .type === 'order-detail-bonuses'
                        "
                        >(BONUS)</span
                      >
                    </p>
                  </td>
                  <td :class="classes.td">
                    {{
                      getProductDetail(product.orderDetailOrBonusId).attributes
                        .product_name
                    }}
                  </td>
                  <td :class="classes.td">
                    {{ product.qty }}
                  </td>
                  <td :class="classes.td">
                    {{ product.batches.length }}
                  </td>
                  <td :class="[classes.td, 'text-center']">
                    <button @click="onRemovePacketProduct(packetIndex, index)">
                      <Icon
                        icon="heroicons:trash-solid"
                        class="h-4 w-4 text-red-500"
                      />
                    </button>
                  </td>
                </tr>
              </template>
            </template>
          </datatable>

          <div v-if="packet.products.length" class="flex justify-end">
            <base-button
              size="sm"
              color="white"
              @click="onAddPacketProduct(packetIndex)"
            >
              <Icon icon="heroicons:plus-solid" class="h-4 w-4" />
              Tambah Produk
            </base-button>
          </div>
        </div>

        <div class="flex justify-end">
          <base-button @click="onAddPacket">
            <Icon icon="heroicons:plus-solid" class="h-4 w-4" />
            Tambah Koli
          </base-button>
        </div>
      </div>
    </base-card>

    <base-card title="Sisa Barang" description="Sisa barang yang belum dikemas">
      <datatable
        :columns="[
          {
            code: 'kode barang',
            name: 'Kode baranB',
          },
          {
            code: 'nama barang',
            name: 'Nama Barang',
          },
          {
            code: 'jumlah',
            name: 'Jumlah',
          },
        ]"
      >
        <template #tbody="{ classes }">
          <template v-if="!productsRemainder.data.length">
            <tr :class="classes.tr">
              <td colspan="3" :class="classes.td">
                <div
                  class="flex flex-col items-center space-y-2 py-4 text-center"
                >
                  <Icon
                    icon="heroicons:check-circle"
                    class="h-6 w-6 text-gray-400"
                  />
                  <p class="text-xs font-normal text-gray-400">
                    Tidak ada data barang<br />yang belum dikemas
                  </p>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr
              v-for="product in productsRemainder.data"
              :key="`${product.type}-${product.id}`"
              :class="classes.tr"
            >
              <td :class="classes.td">
                <p class="font-bold text-gray-900">
                  {{ product.attributes.product_code }}
                  <span v-if="product.type === 'order-detail-bonuses'"
                    >(BONUS)</span
                  >
                </p>
              </td>
              <td :class="classes.td">{{ product.attributes.product_name }}</td>
              <td :class="classes.td">{{ product.attributes.product_qty }}</td>
            </tr>
          </template>
        </template>
      </datatable>
    </base-card>

    <div class="flex justify-end gap-2">
      <base-button :disabled="!valid" @click="visibleSubmit = true">
        <Icon icon="heroicons:archive-box-solid" class="h-4 w-4" />
        Siap Dikemas
      </base-button>

      <slot name="action" />
    </div>

    <select-packet-product-modal
      :products="productsRemainder"
      :product-boxes="productBoxes"
      :packets="packets"
      :visible="selectPacketProductModal.visible"
      @close="selectPacketProductModal.visible = false"
      @confirmed="onConfirmedPacketProduct"
    />

    <base-confirm
      @close="visibleSubmit = false"
      @confirmed="onSubmit"
      size="max-w-xl"
      :message="
        productsRemainder.data.length
          ? 'Barang yang sisa akan dimasukkan ke Susulan, apakah anda yakin akan melanjutkan?'
          : 'Apakah anda yakin data yang dibuat sudah benar?'
      "
      :type="productsRemainder.data.length ? 'warning' : 'success'"
      icon-type="badge"
      :title="
        productsRemainder.data.length ? 'Barang sisa ditemukan' : 'Buat Koli'
      "
      confirm-text="Ya"
      cancel-text="Tutup"
      :visible="visibleSubmit"
    />

    <vue-html2pdf
      v-if="order"
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :paginate-elements-by-height="5000"
      :filename="order.data.attributes.origin_code"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="210mm"
      ref="printStockMovement"
    >
      <LayoutPrintStockMovement
        slot="pdf-content"
        :stock-movement="printStockMovementAttributes"
      />
    </vue-html2pdf>
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import SelectPacketProductModal from '@/components/shipment/select-packet-product-modal.vue';
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import LayoutPrintStockMovement from '@/layouts/LayoutPrintStockMovement.vue';
import VueHtml2pdf from 'vue-html2pdf';
import dayjs from 'dayjs';

export default {
  components: {
    SelectPacketProductModal,
    BaseConfirm,
    LayoutPrintStockMovement,
    VueHtml2pdf,
  },
  mixins: [requestMixin],
  props: {
    orderId: String,
    order: Object,
    loading: Boolean,
  },
  emits: ['update-loading', 'submitted'],
  data() {
    return {
      orderDetails: {
        data: [],
        included: [],
        meta: {
          stock_batches: [],
        },
      },
      orderDetailBonuses: {
        data: [],
        included: [],
        meta: {
          stock_batches: [],
        },
      },
      packets: [],
      productBoxes: [],
      selectPacketProductModal: {
        visible: false,
        index: null,
      },
      stockMovement: null,
      visibleSubmit: false
    };
  },
  computed: {
    productsRemainder() {
      const orderDetails = this.orderDetails.data
        .map((orderDetail) => ({
          id: orderDetail.id,
          type: orderDetail.type,
          relationships: orderDetail.relationships,
          attributes: {
            ...orderDetail.attributes,
            product_qty: this.calculateProductRemainder(orderDetail),
          },
        }))
        .filter((orderDetail) => orderDetail.attributes.product_qty);
      const orderDetailBonuses = this.orderDetailBonuses.data
        .map((orderDetailBonus) => ({
          id: orderDetailBonus.id,
          type: orderDetailBonus.type,
          relationships: orderDetailBonus.relationships,
          attributes: {
            ...orderDetailBonus.attributes,
            product_qty: this.calculateProductRemainder(orderDetailBonus),
          },
        }))
        .filter((orderDetailBonus) => orderDetailBonus.attributes.product_qty);

      return {
        data: [...orderDetails, ...orderDetailBonuses],
        meta: {
          stock_batches: [
            ...this.orderDetails.meta.stock_batches,
            ...this.orderDetailBonuses.meta.stock_batches,
          ],
        },
        included: [
          ...(this.orderDetails.included ?? []),
          ...(this.orderDetailBonuses.included ?? []),
        ],
      };
    },
    printStockMovementAttributes() {
      const destinationOfficeRel =
        this.order.data.relationships['destination-office'].data;
      const destinationOffice = destinationOfficeRel
        ? this.getSingleIncluded(this.order, destinationOfficeRel.id)
        : null;

      return {
        code: this.stockMovement ? this.stockMovement.attributes.code : '-',
        date: this.stockMovement
          ? dayjs(this.stockMovement.attributes.updatedAt).format('ll LT')
          : '-',
        order: {
          code: `${this.order.data.attributes.origin_code}/${this.order.data.attributes.destination_code}`,
          destinationOffice: destinationOffice
            ? destinationOffice.attributes.code
            : '-',
          address: destinationOffice
            ? destinationOffice.attributes.address
            : '-',
          phone: destinationOffice ? destinationOffice.attributes.phone : '-',
        },
        packets: this.packets.map((packet) => ({
          code: packet.code,
          name: packet.name,
          products: packet.products.map((product) => ({
            shipped_product_qty: product.qty,
            type: this.getProductDetail(product.orderDetailOrBonusId).type,
            product_name: this.getProductDetail(product.orderDetailOrBonusId)
              .attributes.product_name,
          })),
        })),
      };
    },
    valid() {
      return (
        this.packets.reduce(
          (total, packet) =>
            total +
            packet.products.reduce((total, product) => total + product.qty, 0),
          0
        ) > 0
      );
    },
  },
  methods: {
    calculateProductRemainder(product) {
      const totalPacketProductQty = this.packets.reduce((total, packet) => {
        const productMatched = packet.products.find(
          (detailProduct) => detailProduct.orderDetailOrBonusId === product.id
        );

        if (!productMatched) {
          return total;
        }

        return total + productMatched.qty;
      }, 0);

      if (product.type === 'order-detail-bonuses') {
        return product.attributes.bonus_qty - totalPacketProductQty;
      }

      return product.attributes.product_not_packed - totalPacketProductQty;
    },
    getProductDetail(id) {
      return [...this.orderDetails.data, ...this.orderDetailBonuses.data].find(
        (orderDetail) => orderDetail.id === id
      );
    },
    async loadOrderDetails() {
      const [res, err] = await this.request(
        `/api/v1/orders/${this.orderId}/order-details`,
        {
          params: {
            include: 'product',
            'fields[order-details]':
              'product_code,product_name,product_not_packed,product,product_id',
            'fields[products]': 'qrcode,is_qrcode_active',
            'filter[with-stock-batches]': true,
          },
        }
      );

      if (!err) {
        this.orderDetails = res;
      }
    },
    async loadOrderDetailBonuses() {
      const [res, err] = await this.request(
        `/api/v1/orders/${this.orderId}/order-detail-bonuses`,
        {
          params: {
            include: 'product',
            'fields[order-detail-bonuses]':
              'product_code,product_name,bonus_qty,product,product_id',
            'fields[products]': 'qrcode,is_qrcode_active',
            'filter[with-stock-batches]': true,
          },
        }
      );

      if (!err) {
        this.orderDetailBonuses = res;
      }
    },
    async loadOrderProductBoxes() {
      const [res, err] = await this.request(
        `/api/v1/orders/${this.orderId}/-actions/product-boxes`
      );

      if (!err) {
        this.productBoxes = res
      }
    },
    async loadProducts() {
      this.$emit('update-loading', true);

      await Promise.all([
        this.loadOrderDetails(),
        this.loadOrderDetailBonuses(),
        this.loadOrderProductBoxes()
      ]);

      this.$emit('update-loading', false);
    },
    onAddPacket() {
      const id = this.packets.length + 1;

      this.packets.push({
        code: `Koli ${id}`,
        name: `Koli ${id}`,
        products: [],
      });
    },
    onAddPacketProduct(index) {
      this.selectPacketProductModal.index = index;
      this.selectPacketProductModal.visible = true;
    },
    onConfirmedPacketProduct(products) {
      const packetProducts =
        this.packets[this.selectPacketProductModal.index].products;

      products.forEach((product) => {
        const productIndex = packetProducts.findIndex(
          (packetProduct) =>
            packetProduct.productId === product.productId &&
            packetProduct.type === product.type
        );

        if (productIndex < 0) {
          this.packets[this.selectPacketProductModal.index].products.push({
            ...product,
          });
        } else {
          this.packets[this.selectPacketProductModal.index].products[
            productIndex
          ].qty += product.qty;
        }
      });

      this.selectPacketProductModal.visible = false;
    },
    onRemovePacket(index) {
      this.packets.splice(index, 1);
    },
    onRemovePacketProduct(packetIndex, index) {
      this.packets[packetIndex].products.splice(index, 1);
    },
    async onSubmit() {
      this.$emit('update-loading', true);

      const [res, err] = await this.request('/api/v1/stock-movements/-actions/create-shipment', {
        method: 'post',
        data: {
          order_id: this.order.data.id,
          packets: this.packets.map(packet => ({
            code: packet.code,
            name: packet.name,
            products: packet.products.map(product => ({
              product_id: product.productId,
              product_type: product.type === 'order-detail-bonuses' ? 'free' : 'reguler',
              batches: product.batches.map(batch => ({
                batch_id: batch.id,
                qty: batch.qty
              }))
            }))
          }))
        }
      });

      if (!err) {
        this.visibleSubmit = false;
        this.stockMovement = res.data;

        this.print();
      }

      this.$emit('update-loading', false);
    },
    print() {
      setTimeout(() => {
        this.$refs.printStockMovement.generatePdf();

        const waitForEl = setInterval(() => {
          const el = document.querySelector(
            'div.vue-html2pdf > section.pdf-preview > button'
          );

          if (el) {
            el.onclick = () => this.$emit('submitted');
            clearInterval(waitForEl);
          }
        }, 100);
      }, 0);
    },
    setPackets() {
      this.packets = [
        {
          code: `Koli 1`,
          name: `Koli 1`,
          products: [],
        },
      ];
    },
  },
  created() {
    this.loadProducts();
    this.setPackets();
  },
};
</script>
