var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-modal',{attrs:{"showing":_vm.visible,"size":"max-w-6xl"},on:{"opened":_vm.onOpened,"close":_vm.onClosed}},[_c('base-card',{attrs:{"title":"Daftar Barang","description":"Data barang dari order yang telah dibuat","with-style":false}},[_c('div',{staticClass:"space-y-4 border-t pt-5"},[_c('div',{staticClass:"flex items-center justify-end gap-4"},[_c('ToggleButton',{attrs:{"labels":{
            checked: 'QR Kurang',
            unchecked: 'QR Tambah',
          },"width":96,"color":"#dc2626"},model:{value:(_vm.qrScan.isDecrementQty),callback:function ($$v) {_vm.$set(_vm.qrScan, "isDecrementQty", $$v)},expression:"qrScan.isDecrementQty"}}),_c('base-input',{attrs:{"shadow":false,"placeholder":"Cari kode"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('datatable',{attrs:{"columns":[
          {
            id: 'check',
            name: '',
            theadClass: 'pr-0',
          },
          {
            id: 'qrcode',
            name: '',
            theadClass: 'pr-0',
          },
          {
            code: 'code',
            name: 'Kode Barang',
          },
          {
            code: 'name',
            name: 'Nama Barang',
          },
          {
            code: 'total',
            name: 'Total Produk',
          },
          {
            code: 'qty',
            name: 'Jumlah',
          },
          {
            code: 'action',
            name: '',
            theadClass: 'text-right',
          },
        ]},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return [(!_vm.products.data.length)?[_c('td',{class:classes.td,attrs:{"colspan":"7"}},[_c('div',{staticClass:"flex flex-col items-center space-y-2 py-4 text-center"},[_c('Icon',{staticClass:"h-6 w-6 text-gray-400",attrs:{"icon":"heroicons:check-circle"}}),_c('p',{staticClass:"text-xs font-normal text-gray-400"},[_vm._v(" Tidak ada data barang"),_c('br'),_vm._v("yang belum dikemas ")])],1)])]:[_vm._l((_vm.products.data),function(product,index){return [_c('tr',{key:`product-${index}`,class:classes.tr},[_c('td',{class:[classes.td, 'pr-0']},[_c('base-checkbox',{attrs:{"input-checked":!!_vm.selectedProducts[index].qty,"disabled":"","with-label":false}})],1),_c('td',{class:[classes.td, 'pr-0']},[(_vm.checkProductHasQr(product))?_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":"heroicons:qr-code"}}):_vm._e()],1),_c('td',{class:classes.td},[_c('p',{staticClass:"font-medium text-gray-900"},[_vm._v(" "+_vm._s(product.attributes.product_code)+" "),(product.type === 'order-detail-bonuses')?_c('span',[_vm._v("(BONUS)")]):_vm._e()])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(product.attributes.product_name)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(product.attributes.product_qty)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.selectedProducts[index].qty ?? 0)+" ")]),_c('td',{class:classes.td},[_c('div',{staticClass:"flex justify-end"},[_c('button',{on:{"click":function($event){return _vm.onToggleBatchProductVisible(index)}}},[_c('Icon',{staticClass:"h-4 w-4",attrs:{"icon":_vm.batchProductVisible.includes(index)
                            ? 'heroicons:chevron-down'
                            : 'heroicons:chevron-right'}})],1)])])]),(_vm.batchProductVisible.includes(index))?_vm._l((_vm.selectedProducts[index]
                    .batches),function(batch,batchIndex){return _c('tr',{key:`product-${index}-batch-${batchIndex}`,class:[classes.tr, 'bg-gray-50']},[_c('td',{class:classes.td},[_c('base-checkbox',{attrs:{"input-checked":!!_vm.selectedProducts[index].batches[batchIndex].qty &&
                        _vm.selectedProducts[index].batches[batchIndex].qty > 0,"disabled":"","with-label":false}})],1),_c('td',{class:classes.td}),_c('td',{class:classes.td},[_c('base-button',{attrs:{"size":"sm","color":_vm.checkBatchScanStatus(index, batchIndex)
                          ? 'warning'
                          : 'white'},on:{"click":function($event){return _vm.onToggleBatchScanQr(index, batchIndex)}}},[_vm._v(" "+_vm._s(_vm.checkBatchScanStatus(index, batchIndex) ? 'Batal Scan QR' : 'Scan QR')+" ")])],1),_c('td',{class:classes.td},[_vm._v(_vm._s(batch.code))]),_c('td',{class:classes.td},[_vm._v(_vm._s(batch.stock))]),_c('td',{class:classes.td},[_c('input',{staticClass:"block border-0 bg-transparent p-0 text-sm placeholder-gray-300 focus:ring-0",attrs:{"type":"text","placeholder":"Masukkan jumlah","disabled":_vm.checkBatchScanStatus(index, batchIndex)},domProps:{"value":_vm.selectedProducts[index].batches[batchIndex].qty},on:{"change":(e) => _vm.onChangeBatchQty(e, index, batchIndex)}})]),_c('td',{class:classes.td})])}):_vm._e()]})]]}}])}),_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-button',{on:{"click":_vm.onConfirm}},[_vm._v("Konfirmasi")]),_c('base-button',{attrs:{"color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Kembali")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }