<template>
  <base-card title="Informasi Pengemasan" description="Detail informasi pengemasan">
    <template #action>
      <base-badge>{{ order.data.attributes.warehouse_status }}</base-badge>
    </template>

    <div class="grid grid-cols-3 gap-6 border-t pt-5">
      <div>
        <dt class="text-xs text-gray-700">No Penjualan</dt>
        <dd class="mt-1 text-sm font-bold text-gray-900">
          {{ order.data.attributes.origin_code ?? '-' }}
        </dd>
      </div>
      <div>
        <dt class="text-xs text-gray-700">Tanggal</dt>
        <dd class="mt-1 text-sm font-bold text-gray-900">
          {{ order.data.attributes.updatedAt | formatDate }}
        </dd>
      </div>
      <div>
        <dt class="text-xs text-gray-700">Kode Stockist</dt>
        <dd class="mt-1 text-sm font-bold text-gray-900">
          {{ destinationOffice ? destinationOffice.attributes.code : '-' }}
        </dd>
      </div>
      <div>
        <dt class="text-xs text-gray-700">Nama Stockist</dt>
        <dd class="mt-1 text-sm font-bold text-gray-900">
          {{ destinationOffice ? destinationOffice.attributes.name : '-' }}
        </dd>
      </div>
      <div>
        <dt class="text-xs text-gray-700">Alamat Stockist 1</dt>
        <dd class="mt-1 text-sm font-bold text-gray-900">
          {{ destinationOffice ? destinationOffice.attributes.address : '-' }}
        </dd>
      </div>
      <div>
        <dt class="text-xs text-gray-700">Alamat Stockist 2</dt>
        <dd class="mt-1 text-sm font-bold text-gray-900">
          {{ destinationOffice ? destinationOffice.attributes.address2 : '-' }}
        </dd>
      </div>
      <div>
        <dt class="text-xs text-gray-700">Nomor Telepon Stockist</dt>
        <dd class="mt-1 text-sm font-bold text-gray-900">
          {{ destinationOffice ? destinationOffice.attributes.phone : '-' }}
        </dd>
      </div>
      <div v-if="order.data.attributes.order_shipment === 'delivery'">
        <dt class="text-xs text-gray-700">Dikirim ke Alamat</dt>
        <dd class="mt-1 text-sm font-bold text-gray-900">
          {{ deliveryAddress ?? '-' }}
        </dd>
      </div>
    </div>
  </base-card>
</template>

<script>
export default {
  props: {
    order: Object
  },
  computed: {
    deliveryAddress() {
      const shipmentAddres = this.order.data.attributes.shipment_address

      if (!shipmentAddres) {
        return null
      }

      const villageRel = this.order.data.relationships.village.data
      const districtRel = this.order.data.relationships.district.data
      const cityRel = this.order.data.relationships.city.data
      const provinceRel = this.order.data.relationships.province.data

      const village = !villageRel ? null : this.getSingleIncluded(this.order, this.order.data.relationships.village.data.id)
      const district = !districtRel ? null : this.getSingleIncluded(this.order, this.order.data.relationships.district.data.id)
      const city = !cityRel ? null : this.getSingleIncluded(this.order, this.order.data.relationships.city.data.id)
      const province = !provinceRel ? null : this.getSingleIncluded(this.order, this.order.data.relationships.province.data.id)

      return [shipmentAddres, village ? village.attributes.name : '', district ? district.attributes.name : '', city ? city.attributes.name : '', province ? province.attributes.name : ''].join(', ')
    },
    destinationOffice() {
      const destinationOffice = this.order.data.relationships['destination-office'].data

      if (!destinationOffice) {
        return null
      }

      return this.getSingleIncluded(this.order, destinationOffice.id)
    }
  }
};
</script>
